import React from 'react';
import logo from '../images/twitterPFP.jpg'

const Title = () => {
  return (
    <div className="title">
      <img src={logo} alt='logo' />
      <h2>Gainz Club NFT</h2><br></br> <h3>Join The Gainz Gym</h3>
    </div>
  )
}

export default Title;